import React, { FC, createContext, useState } from 'react';

import { CognitoUser } from '@aws-amplify/auth';

interface AutoSignInAccountContextConfig {
	autoSignInUser: CognitoUser | undefined;
	setAutoSignInUser: React.Dispatch<React.SetStateAction<CognitoUser | undefined>>;
	emailAddress: string;
	setEmailAddress: React.Dispatch<React.SetStateAction<string>>;
	password: string;
	setPassword: React.Dispatch<React.SetStateAction<string>>;
}

export const AutoSignInContext = createContext({} as AutoSignInAccountContextConfig);

export const AutoSignInProvider: FC = ({ children }) => {
	const [autoSignInUser, setAutoSignInUser] = useState<CognitoUser | undefined>(undefined);
	const [emailAddress, setEmailAddress] = useState('');
	const [password, setPassword] = useState('');

	const value = {
		autoSignInUser,
		setAutoSignInUser,
		emailAddress,
		setEmailAddress,
		password,
		setPassword,
	};

	return <AutoSignInContext.Provider value={value}>{children}</AutoSignInContext.Provider>;
};
