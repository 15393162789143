import { useContext } from 'react';
import { createUseStyles } from 'react-jss';

import { ThemeConfig, ThemeContext } from '@/contexts/theme-context';

export function useTheme() {
	const themeContext = useContext(ThemeContext);
	return themeContext;
}

export function createUseThemedStyles<Theme extends ThemeConfig, C extends string = string>(
	style: Record<C, any> | ((theme: Theme) => Record<C, any>)
) {
	return createUseStyles(style);
}
