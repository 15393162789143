import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const NoMatch = () => {
	return (
		<Container className="py-6">
			<Row>
				<Col>
					<h1 className="text-center text-muted">404: Page Not Found</h1>
				</Col>
			</Row>
		</Container>
	);
};
