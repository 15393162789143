import React from 'react';

import config from '@/lib/config';
import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	renderJson: {
		padding: 16,
		marginTop: 24,
		borderRadius: 4,
		...theme.fonts.xs,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.gray300}`,
	},
}));

interface RenderJsonProps {
	json: any;
}

export const RenderJson = ({ json }: RenderJsonProps) => {
	const classes = useStyles();

	if (config.HACKETT_USER_AUTH_SHOW_DEBUG === 'true') {
		return <pre className={classes.renderJson}>{JSON.stringify(json, null, 4)}</pre>;
	}

	return null;
};
