const openSans = {
	fontFamily: '"Open Sans", sans-serif',
	weights: {
		regular: 400,
		semiBold: 600,
		bold: 700,
	},
};

export const fonts = {
	...openSans,
	xxl: {
		fontSize: '5.0rem',
		lineHeight: 1.2,
	},
	xl: {
		fontSize: '3.6rem',
		lineHeight: 1.2,
	},
	l: {
		fontSize: '2.8rem',
		lineHeight: 1.2,
	},
	m: {
		fontSize: '2.2rem',
		lineHeight: 1.2,
	},
	s: {
		fontSize: '1.6rem',
		lineHeight: 1.5,
	},
	xs: {
		fontSize: '1.4rem',
		lineHeight: 1.5,
	},
	xxs: {
		fontSize: '1.2rem',
		lineHeight: 1.5,
	},
	openSansRegular: {
		fontFamily: openSans.fontFamily,
		fontWeight: openSans.weights.regular,
	},
	openSansSemiBold: {
		fontFamily: openSans.fontFamily,
		fontWeight: openSans.weights.semiBold,
	},
	openSansBold: {
		fontFamily: openSans.fontFamily,
		fontWeight: openSans.weights.bold,
	},
};
