import { createUseThemedStyles } from '@/hooks';

export const useGlobalStyles = createUseThemedStyles((theme) => ({
	'@global': {
		'#root': {
			overflow: 'hidden',
		},

		/* ----------------------------------------------------------- */
		/* Search input controls */
		/* ----------------------------------------------------------- */
		'input[type=search]::-ms-clear': {
			display: 'none',
			width: 0,
			height: 0,
		},
		'input[type=search]::-ms-reveal': {
			display: 'none',
			width: 0,
			height: 0,
		},
		'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration': {
			display: 'none',
		},

		/* ----------------------------------------------------------- */
		/* Placeholder text */
		/* ----------------------------------------------------------- */
		'::-webkit-input-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},
		'::-moz-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},
		':-ms-input-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},
		':-moz-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},

		/* ----------------------------------------------------------- */
		/* Generics */
		/* ----------------------------------------------------------- */
		html: {
			height: '100%',
			fontSize: '10px',
		},
		body: {
			...theme.fonts.s,
			minHeight: '100%',
			position: 'relative',
			color: theme.colors.black,
			...theme.fonts.openSansRegular,
			backgroundColor: theme.colors.gray100,
		},
		hr: {
			margin: 0,
		},
	},
}));
