import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Card, Modal, Button } from 'react-bootstrap';
import { Auth, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core';
import { CognitoUser } from '@aws-amplify/auth';

import config from '@/lib/config';
import { AccountInviteModel, COOKIES } from '@/lib/models';
import { publicService } from '@/lib/services';
import { useAlert, useAutoSignInContext, useHandleError, useQuery, useRedirectUser } from '@/hooks';
import { AsyncPage, LoadingButton } from '@/components';

const emailObfuscationRegex = /(\w)(.+?)(@)(\w)(.+?)(.\w+$)/gm;
const emailObfuscationSubstring = '$1***$3$4***$6';

export const AcceptInvite = () => {
	const redirectUser = useRedirectUser();
	const { setAutoSignInUser, setEmailAddress, setPassword } = useAutoSignInContext();
	const history = useHistory();
	const { inviteId } = useParams<{ inviteId: string }>();
	const query = useQuery();
	const sessionExpired = useMemo(() => query.get('sessionExpired') === 'true', [query]);

	const handleError = useHandleError();
	const { showAlert } = useAlert();

	const [user, setUser] = useState<CognitoUser>();
	const [userEmail, setUserEmail] = useState('N/A');
	const [showAlreadyAuthenticatedModal, setShowAlreadyAuthenticatedModal] = useState(false);
	const [invite, setInvite] = useState<AccountInviteModel>();
	const [passwordInputValue, setPasswordInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [showCodeModal, setShowCodeModal] = useState(false);
	const [codeInputValue, setCodeInputValue] = useState('');

	// Display message after page reload (like from session expire).
	useEffect(() => {
		if (!sessionExpired) {
			return;
		}

		showAlert({
			variant: 'danger',
			children: () => {
				return <p className="mb-0 text-white">Session expired.</p>;
			},
		});
	}, [sessionExpired, showAlert]);

	const fetchData = useCallback(async () => {
		if (config.HACKETT_USER_AUTH_USE_REAL_AUTH === 'true') {
			try {
				const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
				const userAttributes = await Auth.userAttributes(currentUser);
				const emailAttributeValue =
					userAttributes.find((cognitoUserAttribute) => {
						return cognitoUserAttribute.Name === 'email';
					})?.Value ?? 'N/A';

				setUser(currentUser);
				setUserEmail(emailAttributeValue);
				setShowAlreadyAuthenticatedModal(true);
			} catch (error) {
				// Fail silently, and fetch the invite.
				const response = await publicService.getInvite(inviteId).fetch();
				setInvite(response);
			}
		} else {
			const response = await publicService.getInvite(inviteId).fetch();
			setInvite(response);
		}
	}, [inviteId]);

	async function handlePasswordFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			if (!invite) {
				throw new Error('invite is required.');
			}

			setIsLoading(true);
			const platformJson = JSON.stringify(invite.platforms.map((it) => it.platformId));
			if (config.HACKETT_USER_AUTH_USE_REAL_AUTH === 'true') {
				await Auth.signUp({
					username: invite.emailAddress,
					password: passwordInputValue,
					attributes: { 'custom:platform': platformJson },
					autoSignIn: {
						enabled: true,
					},
				});
			}

			setShowCodeModal(true);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleSendANewCodeButtonClick() {
		try {
			if (!invite) {
				throw new Error('invite is required.');
			}

			setIsLoading(true);

			if (config.HACKETT_USER_AUTH_USE_REAL_AUTH === 'true') {
				await Auth.resendSignUp(invite.emailAddress);

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">A new code has been sent.</p>;
					},
				});
			} else {
				showAlert({
					variant: 'warning',
					children: () => {
						return <p className="mb-0 text-white">Environment not configured to support this feature.</p>;
					},
				});
			}
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleCodeFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setIsLoading(true);

		if (config.HACKETT_USER_AUTH_USE_REAL_AUTH === 'true') {
			try {
				if (!invite) {
					throw new Error('invite is required.');
				}

				await Auth.confirmSignUp(invite.emailAddress, codeInputValue.replace(/\s/g, ''));

				// If confirmSignUp is successful, this will send an 'autoSignIn' event to the Auth Hub.
				// The Hub is responsible for directing the user at this point.
			} catch (error) {
				if ((error as any).name === 'UserNotFoundException') {
					window.location.replace(`${window.location.href}?sessionExpired=true`);
					return;
				}

				if ((error as any).name === 'NotAuthorizedException') {
					(error as any).message = 'This invite has already been accepted.';
				}

				handleError(error);
				setIsLoading(false);
			}
		} else {
			try {
				if (!invite) {
					throw new Error('invite is required.');
				}

				const { signOnUrl } = (await publicService.accountInvite(invite.inviteId).fetch()).platforms[0];
				Cookies.set(COOKIES.REDIRECT_URL, signOnUrl);

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Account confirmed.</p>;
					},
				});
			} catch (error) {
				handleError(error);
				setIsLoading(false);
			}
		}
	}

	// AWS Amplify Hub handles listening to 'auth' events.
	// https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
	useEffect(() => {
		const handleHubAuthEvent = async ({ payload }: HubCapsule) => {
			const { event } = payload;

			// If a 'autoSignIn' event is detected, set the auto sign in user to the payload and direct the user to the sign-in page.
			// Thats where all the challange logic is for passwords / MFA / etc.
			if (event === 'autoSignIn') {
				if (!invite) {
					throw new Error('invite is required.');
				}

				const acceptedInvite = (await publicService.accountInvite(invite.inviteId).fetch()).platforms[0];
				Cookies.set(COOKIES.REDIRECT_URL, acceptedInvite.signOnUrl);

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Account confirmed.</p>;
					},
				});
				setAutoSignInUser(payload.data);
				setEmailAddress(invite?.emailAddress ?? '');
				setPassword(passwordInputValue);

				history.push('/sign-in');
			} else if (event === 'autoSignIn_failure') {
				if (!invite) {
					throw new Error('invite is required.');
				}

				const acceptedInvite = (await publicService.accountInvite(invite.inviteId).fetch()).platforms[0];
				Cookies.set(COOKIES.REDIRECT_URL, acceptedInvite.signOnUrl);

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Account confirmed.</p>;
					},
				});

				history.push('/sign-in');
			}
		};

		Hub.listen('auth', handleHubAuthEvent);
		return () => {
			Hub.remove('auth', handleHubAuthEvent);
		};
	}, [history, invite, passwordInputValue, setAutoSignInUser, setEmailAddress, setPassword, showAlert]);

	return (
		<AsyncPage fetchData={fetchData}>
			<Modal
				centered
				show={showAlreadyAuthenticatedModal}
				onHide={() => {
					return;
				}}
			>
				<Modal.Header>
					<Modal.Title>Already Authenticated</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="mb-0">
						You are already signed in as <span className="bold">{userEmail}</span>, do you want to continue?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex justify-content-end align-items-center">
						<Button
							variant="link"
							className="mr-4"
							onClick={async () => {
								await Auth.signOut();
								const response = await publicService.getInvite(inviteId).fetch();
								setInvite(response);
								setShowAlreadyAuthenticatedModal(false);
							}}
						>
							Sign Out and Accept Invite
						</Button>
						{user && (
							<Button
								onClick={() => {
									const jwtToken = user
										.getSignInUserSession()
										?.getIdToken()
										.getJwtToken();

									redirectUser(jwtToken);
								}}
							>
								Continue
							</Button>
						)}
					</div>
				</Modal.Footer>
			</Modal>

			<Modal
				centered
				show={showCodeModal}
				onHide={() => {
					return;
				}}
			>
				<Modal.Header>
					<Modal.Title>Confirm Your Account</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleCodeFormSubmit}>
					<Modal.Body>
						<p className="mb-4">
							We have sent a code by email to{' '}
							{invite?.emailAddress.replace(emailObfuscationRegex, emailObfuscationSubstring)}. Enter it
							below to confirm your account.
						</p>
						<Form.Group className="mb-2">
							<Form.Label>Verification Code</Form.Label>
							<Form.Control
								type="tel"
								value={codeInputValue}
								onChange={(event) => {
									setCodeInputValue(event.currentTarget.value);
								}}
								required
							/>
						</Form.Group>
						<div className="text-right">
							<Button variant="link" onClick={handleSendANewCodeButtonClick}>
								Send a New Code
							</Button>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="d-flex justify-content-end">
							<LoadingButton
								isLoading={isLoading}
								disabled={!codeInputValue}
								variant="primary"
								type="submit"
							>
								Confirm Account
							</LoadingButton>
						</div>
					</Modal.Footer>
				</Form>
			</Modal>

			<Container className="py-6">
				<Row className="mb-5">
					<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
						<h1 className="mb-0">Accept Invite</h1>
					</Col>
				</Row>
				<Row>
					<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
						<Card>
							<Card.Header>
								<Card.Title>Account Setup</Card.Title>
							</Card.Header>
							<Card.Body>
								<Form onSubmit={handlePasswordFormSubmit}>
									<Form.Group>
										<Form.Label>Email Address</Form.Label>
										<Form.Control type="text" value={invite?.emailAddress} readOnly disabled />
									</Form.Group>
									<Form.Group>
										<Form.Label>Password</Form.Label>
										<Form.Control
											type="password"
											value={passwordInputValue}
											onChange={(event) => {
												setPasswordInputValue(event.currentTarget.value);
											}}
											required
										/>
										<ul className="pt-2 pl-4 small">
											<li>One lowercase letter</li>
											<li>One uppercase letter</li>
											<li>One number</li>
											<li>One special character</li>
											<li>Eight characters minimum</li>
										</ul>
									</Form.Group>
									<div className="text-right">
										<LoadingButton
											isLoading={isLoading}
											type="submit"
											disabled={!passwordInputValue}
										>
											Submit
										</LoadingButton>
									</div>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
