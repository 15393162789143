import { ENV_VARS } from './constants';

export type Environment = {
	[varName in ENV_VARS]: string;
};

function processEnvToConfig(env: NodeJS.ProcessEnv): Readonly<Environment> {
	return {
		HACKETT_USER_AUTH_BASE_URL: env.REACT_APP_HACKETT_USER_AUTH_BASE_URL || '',
		HACKETT_USER_AUTH_SHOW_DEBUG: env.REACT_APP_HACKETT_USER_AUTH_SHOW_DEBUG || '',
		HACKETT_USER_AUTH_USE_REAL_AUTH: env.REACT_APP_HACKETT_USER_AUTH_USE_REAL_AUTH || '',
		HACKETT_USER_AUTH_AMPLIFY_REGION: env.REACT_APP_HACKETT_USER_AUTH_AMPLIFY_REGION || '',
		HACKETT_USER_AUTH_AMPLIFY_USER_POOL_ID: env.REACT_APP_HACKETT_USER_AUTH_AMPLIFY_USER_POOL_ID || '',
		HACKETT_USER_AUTH_AMPLIFY_USER_POOL_WEB_CLIENT_ID: env.REACT_APP_HACKETT_USER_AUTH_AMPLIFY_USER_POOL_WEB_CLIENT_ID || '',
		HACKETT_USER_AUTH_PACKAGE_VERSION: env.HACKETT_USER_AUTH_PACKAGE_VESRION || '',
		SENTRY_DSN: env.REACT_APP_SENTRY_DSN || ''
	};
}

export function getEnvConfig(): Readonly<Environment> {
	return processEnvToConfig(process.env);
}
