import Cookies from 'js-cookie';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';
import { Amplify } from 'aws-amplify';
import { createBrowserHistory } from 'history';

import config from '@/lib/config';
import { COOKIES } from '@/lib/models';

import { usePlatformsContext, useQuery } from '@/hooks';
import {
	AlertProvider,
	AutoSignInProvider,
	ThemeProvider as MasterClientThemeProvider,
	PlatformsProvider,
} from '@/contexts';

import { Alert, Footer, PrivateRoute } from '@/components';

import { prefixes } from '@/jss/bootstrap';
import { useGlobalStyles } from '@/jss/hooks/use-global-styles';
import { useThemedBootstrapStyles } from '@/jss/hooks/use-themed-bootstrap-styles';

import { routes } from '@/routes';

import 'react-phone-number-input/style.css';

import * as Sentry from '@sentry/react';
import { publicService } from './lib/services';

Amplify.configure({
	region: config.HACKETT_USER_AUTH_AMPLIFY_REGION,
	userPoolId: config.HACKETT_USER_AUTH_AMPLIFY_USER_POOL_ID,
	userPoolWebClientId: config.HACKETT_USER_AUTH_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
});

// Wrapped by <Router>, allows use of 'react-router-dom' hooks
const RoutedApp = () => {
	const { pathname } = useLocation();
	const query = useQuery();
	const { setPlatforms } = usePlatformsContext();
	const redirectUrl = decodeURIComponent(query.get('redirectUrl') || '');
	const platform = useMemo(() => query.get('platform') ?? '', [query]);

	// When the application mounts, save the URL that user came from into a cookie.
	// That way we can navigate them back to their destination after they authenticate.
	useEffect(() => {
		if (redirectUrl) {
			Cookies.set(COOKIES.REDIRECT_URL, redirectUrl);
		}
	}, [redirectUrl]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		const fetchPlatforms = async () => {
			if (!platform) {
				return;
			}

			const response = await publicService.getPlatforms({ platform: [platform] }).fetch();
			setPlatforms(response.platforms);
		};

		fetchPlatforms();
	}, [platform, setPlatforms]);

	return (
		<>
			<Alert />

			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.header />} />;
				})}
			</Switch>

			<Switch>
				{routes.map((route, index) => {
					return React.createElement(route.private ? PrivateRoute : Route, {
						key: index,
						path: route.path,
						exact: route.exact,
						children: <route.main />,
					});
				})}
			</Switch>

			<Footer />
		</>
	);
};

// Wrapped by various <ThemeProviders>, allows use of 'jss' hooks
const ThemedApp = () => {
	useGlobalStyles();
	useThemedBootstrapStyles();

	return (
		<Router>
			<RoutedApp />
		</Router>
	);
};

// Exported App, not wrapped by anything, can add various context providers here
const App = () => {
	return (
		<MasterClientThemeProvider>
			<BootstrapThemeProvider prefixes={prefixes}>
				<AutoSignInProvider>
					<AlertProvider>
						<PlatformsProvider>
							<ThemedApp />
						</PlatformsProvider>
					</AlertProvider>
				</AutoSignInProvider>
			</BootstrapThemeProvider>
		</MasterClientThemeProvider>
	);
};

Sentry.init({
	dsn: config.SENTRY_DSN,
	release: __VERSION__,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory()),
		}),
		// DO NOT USE, causes performance slow down
		//new Sentry.Replay(),
	],

	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default App;
