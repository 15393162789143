import React, { FC, useCallback, useRef } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { AsyncPage } from '@/components';
import { Auth } from 'aws-amplify';

const PrivateView: FC = ({ children }) => {
	const routeIsInitialized = useRef(false);

	const fetchData = useCallback(async () => {
		if (routeIsInitialized.current) {
			return;
		}

		await Auth.currentAuthenticatedUser();
		routeIsInitialized.current = true;
	}, []);

	return <AsyncPage fetchData={fetchData}>{children}</AsyncPage>;
};

export const PrivateRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
	return <Route {...routeProps} render={() => <PrivateView>{children}</PrivateView>} />;
};
