import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import { useAlert, useHandleError } from '@/hooks';
import { LoadingButton } from '@/components';

export const ForgotPasswordSubmit = () => {
	const history = useHistory<{ emailAddress?: string }>();
	const handleError = useHandleError();
	const { showAlert } = useAlert();

	const [emailAddressInputValue, setEmailAddressInputValue] = useState('');
	const [codeInputValue, setCodeInputValue] = useState('');
	const [newPasswordInputValue, setNewPasswordCodeInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!history.location.state?.emailAddress) {
			return history.replace('/forgot-password');
		}

		setEmailAddressInputValue(history.location.state.emailAddress);
	}, [history]);

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);
			await Auth.forgotPasswordSubmit(
				emailAddressInputValue,
				codeInputValue.replace(/\s/g, ''),
				newPasswordInputValue
			);

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Password changed successfully.</p>;
				},
			});

			history.push('/sign-in');
		} catch (error) {
			handleError(error);
			setIsLoading(false);
		}
	}

	return (
		<Container className="py-6">
			<Row className="mb-5">
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<h1 className="mb-0">Forgot Password</h1>
				</Col>
			</Row>
			<Row>
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<Card>
						<Card.Header>
							<Card.Title>Step 2: Reset Password</Card.Title>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleFormSubmit}>
								<Form.Group>
									<Form.Label>Email Address</Form.Label>
									<Form.Control
										className="mb-2"
										type="email"
										value={emailAddressInputValue}
										onChange={(event) => {
											setEmailAddressInputValue(event.currentTarget.value);
										}}
										required
										disabled
									/>
									<p className="text-muted">
										If you did not recieve an email containing a password reset code, please
										double-check you have entered the correct email address.
									</p>
								</Form.Group>
								<Form.Group>
									<Form.Label>Code</Form.Label>
									<Form.Control
										type="text"
										value={codeInputValue}
										onChange={(event) => {
											setCodeInputValue(event.currentTarget.value);
										}}
										required
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>New Password</Form.Label>
									<Form.Control
										type="password"
										value={newPasswordInputValue}
										onChange={(event) => {
											setNewPasswordCodeInputValue(event.currentTarget.value);
										}}
										required
									/>
									<ul className="pt-2 pl-4 small">
										<li>One lowercase letter</li>
										<li>One uppercase letter</li>
										<li>One number</li>
										<li>One special character</li>
										<li>Eight characters minimum</li>
									</ul>
								</Form.Group>
								<div className="text-right">
									<Button
										variant="link"
										className="mr-4"
										onClick={() => {
											history.goBack();
										}}
									>
										Go Back
									</Button>
									<LoadingButton
										isLoading={isLoading}
										type="submit"
										disabled={!emailAddressInputValue || !codeInputValue || !newPasswordInputValue}
									>
										Reset Password
									</LoadingButton>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
