import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

import { Loader } from '@/components';
import { publicService } from '@/lib/services';
import { PlatformModel } from '@/lib/models';
import { constructUrl } from '@/lib/utils';

interface PlatformSelectionModalProps extends ModalProps {
	user: CognitoUser;
}

export const PlatformSelectionModal = ({ user, ...props }: PlatformSelectionModalProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [platforms, setPlatforms] = useState<PlatformModel[]>([]);

	const redirectUserToUrl = useCallback(
		(url: string) => {
			const jwtToken = user
				.getSignInUserSession()
				?.getIdToken()
				.getJwtToken();

			if (jwtToken) {
				const urlObj = new URL(url);
				const searchParams = new URLSearchParams();
				searchParams.set('X-Hackett-Access-Token', jwtToken);

				window.location.href = constructUrl(
					`${urlObj.origin}${urlObj.pathname}`,
					Object.fromEntries(searchParams)
				);
			} else {
				window.location.href = url;
			}
		},
		[user]
	);

	const handleOnEnter = useCallback(async () => {
		try {
			setIsLoading(true);

			const userAttributes = await Auth.userAttributes(user);
			const platformAttributeValue = userAttributes.find((cognitoUserAttribute) => {
				return cognitoUserAttribute.Name === 'custom:platform';
			})?.Value;
			const parsedPlatformAttributeValue: string[] = platformAttributeValue
				? JSON.parse(platformAttributeValue)
				: [];
			const response = await publicService.getPlatforms({ platform: parsedPlatformAttributeValue }).fetch();

			if (response.platforms.length === 1) {
				redirectUserToUrl(response.platforms[0].signOnUrl);
			}

			setPlatforms(response.platforms);
			setIsLoading(false);
		} catch (error) {
			window.location.href = '/';
		}
	}, [redirectUserToUrl, user]);

	return (
		<Modal centered onEnter={handleOnEnter} {...props}>
			<Modal.Header>
				<Modal.Title>Select Platform</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{platforms.map((platform) => {
							return (
								<Button
									className="d-block w-100 mb-2"
									onClick={() => redirectUserToUrl(platform.signOnUrl)}
								>
									{platform.platformName}
								</Button>
							);
						})}
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<div>
					<Button variant="link" onClick={props.onHide}>
						Close
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
