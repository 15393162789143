import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js/core';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	phoneNumberInput: {
		flex: 1,
		height: 40,
		borderRadius: 2,
		padding: '0 15px',
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.gray500}`,
		boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.16)',
		'&:hover': {
			border: `1px solid ${theme.colors.gray600}`,
		},
		'&:focus-within': {
			border: `1px solid ${theme.colors.primary}`,
		},
		'& .PhoneInput': {
			height: '100%',
		},
		'& .PhoneInputCountry': {
			flexShrink: 0,
			marginRight: 10,
		},
		'& .PhoneInputInput': {
			border: 0,
			padding: 0,
			...theme.fonts.s,
			lineHeight: 'normal',
			backgroundColor: 'transparent',
			'&:focus': {
				outline: 'none',
			},
		},
	},
}));

interface PhoneNumberInputProps {
	value: E164Number | undefined;
	onChange(value?: E164Number | undefined): void;
	disabled?: boolean;
	required?: boolean;
}

export const PhoneNumberInput = ({ ...props }: PhoneNumberInputProps) => {
	const classes = useStyles();

	return (
		<div className={classes.phoneNumberInput}>
			<PhoneInput defaultCountry="US" {...props} />
		</div>
	);
};
