import Cookies from 'js-cookie';
import config from '@/lib/config';
import { HttpClient } from './http-client';
import { COOKIES } from '../models';

export * from './errors';

export const httpSingleton = new HttpClient({
	baseUrl: config.HACKETT_USER_AUTH_BASE_URL,
	defaultHeaders: {
		'Content-Type': 'application/json',
	},
	tokenHeaderKey: 'Authorization',
	tokenHeaderType: 'Bearer',
	getToken: async () => {
		try {
			return Cookies.get(COOKIES.ACCESS_TOKEN);
		} catch (error) {
			return undefined;
		}
	},
});
