import React from 'react';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

const useLoaderStyles = createUseThemedStyles((theme) => ({
	loader: ({ size }: { size: number }) => ({
		width: size,
		height: size,
		borderRadius: '50%',
		border: `${size * 0.14}px solid ${theme.colors.gray200}`,
		borderTopColor: theme.colors.primary,
		animation: `$rotate 1000ms linear 0ms infinite normal`,
	}),
	'@keyframes rotate': {
		'0%': {
			transform: 'rotate(0deg)',
		},
		'100%': {
			transform: 'rotate(360deg)',
		},
	},
}));

interface LoadingProps {
	size?: number;
	className?: string;
}

export const Loader = React.memo(({ size = 56, className }: LoadingProps) => {
	const classes = useLoaderStyles({ size });

	return (
		<div className={classNames('text-center', className)}>
			<div className={classes.loader} />
		</div>
	);
});

Loader.displayName = 'Loader';
