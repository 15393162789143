import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import { handleDangerousHtmlClick } from '@/lib/utils';
import { useHandleError, usePlatformsContext } from '@/hooks';
import { LoadingButton } from '@/components';

export const ForgotPassword = () => {
	const history = useHistory();
	const handleError = useHandleError();
	const { platforms } = usePlatformsContext();
	const platform = useMemo(() => platforms[0], [platforms]);

	const [emailAddressInputValue, setEmailAddressInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);
			await Auth.forgotPassword(emailAddressInputValue);

			history.push('/forgot-password-submit', { emailAddress: emailAddressInputValue });
		} catch (error) {
			handleError(error);
			setIsLoading(false);
		}
	}

	return (
		<Container className="py-6">
			<Row className="mb-5">
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<h1 className="mb-0">Forgot Password</h1>
				</Col>
			</Row>
			<Row>
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<Card>
						<Card.Header>
							<Card.Title>Step 1: Request Reset Code</Card.Title>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleFormSubmit}>
								<div
									dangerouslySetInnerHTML={{ __html: platform?.ctaHtmlBottom ?? '' }}
									onClick={handleDangerousHtmlClick}
								/>
								<Form.Group>
									<Form.Label>Email Address</Form.Label>
									<Form.Control
										type="email"
										value={emailAddressInputValue}
										onChange={(event) => {
											setEmailAddressInputValue(event.currentTarget.value);
										}}
										required
									/>
								</Form.Group>
								<div className="text-right">
									<Button
										variant="link"
										className="mr-4"
										onClick={() => {
											history.goBack();
										}}
									>
										Cancel
									</Button>
									<LoadingButton
										isLoading={isLoading}
										type="submit"
										disabled={!emailAddressInputValue}
									>
										Send Reset Code
									</LoadingButton>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
