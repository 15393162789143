import React, { useCallback, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { E164Number } from 'libphonenumber-js/core';

import { MFA_TYPES, PREFERRED_MFA_TYPES } from '@/lib/models';
import { useAutoSignInContext, useHandleError } from '@/hooks';
import { LoadingButton, PhoneNumberInput } from '@/components';
import { publicService } from '@/lib/services';

interface MfaSmsSetupModalProps extends ModalProps {
	username: string;
	firstTimeSetup: boolean;
	onBack(): void;
	onSuccess(user: CognitoUser): void;
}

export const MfaSmsSetupModal = ({ username, firstTimeSetup, onBack, onSuccess, ...props }: MfaSmsSetupModalProps) => {
	const handleError = useHandleError();
	const { emailAddress, password } = useAutoSignInContext();

	const [fauxSignedInUser, setFauxSignedInUser] = useState<CognitoUser>();
	const [isLoading, setIsLoading] = useState(false);
	const [phoneNumberInputValue, setPhoneNumberInputValue] = useState<E164Number>();
	const [codeInputValue, setCodeInputValue] = useState('');
	const [codeSent, setCodeSent] = useState(false);

	const handleOnEnter = useCallback(() => {
		setFauxSignedInUser(undefined);
		setIsLoading(false);
		setPhoneNumberInputValue('');
		setCodeInputValue('');
		setCodeSent(false);
	}, []);

	async function handleSendCodeButtonClick() {
		if (firstTimeSetup) {
			try {
				await publicService
					.updateAndVerifyUserPhoneNumber({
						username,
						phoneNumber: String(phoneNumberInputValue),
					})
					.fetch();
				const signedInUser = await Auth.signIn(emailAddress, password);

				setFauxSignedInUser(signedInUser);
				setCodeSent(true);
			} catch (publicServiceError) {
				handleError(publicServiceError);
			} finally {
				setIsLoading(false);
			}

			return;
		}

		try {
			setIsLoading(true);

			const currentAuthenticatedUser: CognitoUser = await Auth.currentAuthenticatedUser();
			await Auth.updateUserAttributes(currentAuthenticatedUser, { phone_number: phoneNumberInputValue });
			await Auth.verifyUserAttribute(currentAuthenticatedUser, 'phone_number');

			setCodeSent(true);
		} catch (amplifyError) {
			handleError(amplifyError);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);

			const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
			await Auth.verifyUserAttributeSubmit(currentUser, 'phone_number', codeInputValue);
			await Auth.setPreferredMFA(currentUser, MFA_TYPES.SMS);

			onSuccess(currentUser);
		} catch (userUnauthenticatedError) {
			try {
				const cognitoUser: CognitoUser = await Auth.confirmSignIn(
					fauxSignedInUser,
					codeInputValue.replace(/\s/g, ''),
					PREFERRED_MFA_TYPES.SMS_MFA
				);

				onSuccess(cognitoUser);
			} catch (confirmSignInError) {
				handleError(confirmSignInError);
			}
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal centered onEnter={handleOnEnter} {...props}>
			<Modal.Header>
				<Modal.Title>Set up SMS MFA</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleFormSubmit}>
				<Modal.Body>
					<Form.Group className="mb-2">
						<Form.Label>Phone Number</Form.Label>
						<PhoneNumberInput
							value={phoneNumberInputValue}
							onChange={setPhoneNumberInputValue}
							disabled={isLoading}
							required
						/>
					</Form.Group>
					<div className="text-right mb-6">
						<Button
							variant="link"
							onClick={handleSendCodeButtonClick}
							disabled={!phoneNumberInputValue || isLoading}
						>
							Send Verification Code
						</Button>
					</div>
					<Form.Group className="mb-0">
						<Form.Label>Verification Code</Form.Label>
						<Form.Control
							type="tel"
							value={codeInputValue}
							onChange={(event) => setCodeInputValue(event.target.value)}
							disabled={!codeSent}
							required
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex justify-content-end align-items-center">
						<Button className="mr-4" variant="link" onClick={onBack}>
							Back
						</Button>
						<LoadingButton
							isLoading={isLoading}
							disabled={!phoneNumberInputValue || !codeInputValue}
							variant="primary"
							type="submit"
						>
							Save
						</LoadingButton>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
