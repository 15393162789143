import Cookies from 'js-cookie';
import { COOKIES } from '@/lib/models';

export function constructUrl(baseUrl: string, queryParameters?: Record<string, any>) {
	if (queryParameters) {
		const queryString = Object.keys(queryParameters)
			.map((key) => {
				const value = queryParameters[key];

				if (Array.isArray(value)) {
					return value
						.map((v) => {
							return `${key}=${v}`;
						})
						.join('&');
				}

				return `${key}=${value}`;
			})
			.join('&');

		return `${baseUrl}?${queryString}`;
	}

	return baseUrl;
}

export function getParsedQuery(query: URLSearchParams) {
	const parsedQuery: Record<string, any> = {};

	for (const keyValuePair of query.entries()) {
		const key = keyValuePair[0];
		const value = keyValuePair[1];

		if (parsedQuery[key]) {
			if (Array.isArray(parsedQuery[key])) {
				parsedQuery[key].push(value);
			} else {
				const previousValue = parsedQuery[key];
				parsedQuery[key] = [previousValue, value];
			}
		} else {
			parsedQuery[key] = value;
		}
	}

	return parsedQuery;
}

export const handleDangerousHtmlClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
	const anchorElement = event.currentTarget.querySelector('a');

	if (anchorElement && event.currentTarget.contains(anchorElement)) {
		Cookies.set(COOKIES.UPDATE_CREDENTIALS_LINK_CLICKED, 'true');
	}
};
