import React, { useState } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import { useAlert, useHandleError, useRedirectUser } from '@/hooks';
import { LoadingButton } from '@/components';

export const ChangePassword = () => {
	const redirectUser = useRedirectUser();
	const handleError = useHandleError();
	const { showAlert } = useAlert();

	const [oldPasswordInputValue, setOldPasswordInputValue] = useState('');
	const [newPasswordInputValue, setNewPasswordInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);
			const user = await Auth.currentAuthenticatedUser();
			await Auth.changePassword(user, oldPasswordInputValue, newPasswordInputValue);

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							Password changed successfully. Redirecting back to your application...
						</p>
					);
				},
			});

			redirectUser();
		} catch (error) {
            if(error.code === 'NotAuthorizedException'){
				showAlert({
					variant: 'danger',
					children: () => {
						return (
							<p className="mb-0 text-white">
								<strong>Error:</strong> Incorrect password.
							</p>
						);
					},
				});
			}
			else{
			handleError(error);
			}
			setIsLoading(false);
		}
	}

	return (
		<Container className="py-6">
			<Row className="mb-5">
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<h1 className="mb-0">Change Password</h1>
				</Col>
			</Row>
			<Row>
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
					<Card>
						<Card.Header>
							<Card.Title>Enter A New Password</Card.Title>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleFormSubmit}>
								<Form.Group>
									<Form.Label>Old Password</Form.Label>
									<Form.Control
										className="mb-2"
										type="password"
										value={oldPasswordInputValue}
										onChange={(event) => {
											setOldPasswordInputValue(event.currentTarget.value);
										}}
										required
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>New password</Form.Label>
									<Form.Control
										type="password"
										value={newPasswordInputValue}
										onChange={(event) => {
											setNewPasswordInputValue(event.currentTarget.value);
										}}
										required
									/>
									<ul className="pt-2 pl-4 small">
										<li>One lowercase letter</li>
										<li>One uppercase letter</li>
										<li>One number</li>
										<li>One special character</li>
										<li>Eight characters minimum</li>
									</ul>
								</Form.Group>
								<div className="text-right">
									<Button
										variant="link"
										className="mr-4"
										onClick={() => {
											redirectUser();
										}}
									>
										Cancel
									</Button>
									<LoadingButton
										isLoading={isLoading}
										type="submit"
										disabled={!oldPasswordInputValue || !newPasswordInputValue}
									>
										Change Password
									</LoadingButton>
								</div>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
