import { ThemeConfig } from '@/contexts';

export const modal = (theme: ThemeConfig) => ({
	'.hackett-modal': {
		'&__header': {
			padding: '20px 30px',
			borderBottom: `1px solid ${theme.colors.gray300}`,
		},
		'&__title.h4': {
			margin: 0,
			...theme.fonts.m,
			textTransform: 'none',
			letterSpacing: '0.025rem',
			...theme.fonts.openSansBold,
		},
		'&__body': {
			padding: 30,
		},
		'&__footer': {
			padding: '20px 30px',
			borderTop: `1px solid ${theme.colors.gray300}`,
		},
	},
});
