import { MFA_TYPES } from '@/lib/models';
import React, { useCallback, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';

interface MfaChoiceModalProps extends ModalProps {
	onTotpClick(): void;
	onSmsClick(): void;
}

export const MfaChoiceModal = ({ onTotpClick, onSmsClick, ...props }: MfaChoiceModalProps) => {
	const [mfaMethod, setMfaMethod] = useState<MFA_TYPES>(MFA_TYPES.NOMFA);

	const handleOnEnter = useCallback(() => {
		setMfaMethod(MFA_TYPES.NOMFA);
	}, []);

	const handleContinueButtonClick = useCallback(() => {
		switch (mfaMethod) {
			case MFA_TYPES.SMS:
				onSmsClick();
				break;
			case MFA_TYPES.TOTP:
				onTotpClick();
				break;
			case MFA_TYPES.NOMFA:
			default:
				return;
		}
	}, [mfaMethod, onSmsClick, onTotpClick]);

	return (
		<Modal centered onEnter={handleOnEnter} {...props}>
			<Modal.Header>
				<Modal.Title>Choose Multi-Factor Authentication (MFA) Method</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="mb-4">
					You must configure MFA before you can sign in. Choose how you want to authenticate.
				</p>
				<Form.Check
					type="radio"
					className="mb-4"
					name="mfa-method"
					id="mfa-method--sms"
					value={MFA_TYPES.SMS}
					checked={mfaMethod === MFA_TYPES.SMS}
					onChange={({ currentTarget }) => {
						setMfaMethod(currentTarget.value as MFA_TYPES);
					}}
					label={
						<div>
							<p className="mb-0">
								<strong>SMS</strong>
							</p>
							<p className="mb-0">Authenticate using a code sent via text message to your phone.</p>
						</div>
					}
				/>
				<Form.Check
					type="radio"
					name="mfa-method"
					id="mfa-method--totp"
					value={MFA_TYPES.TOTP}
					checked={mfaMethod === MFA_TYPES.TOTP}
					onChange={({ currentTarget }) => {
						setMfaMethod(currentTarget.value as MFA_TYPES);
					}}
					label={
						<div>
							<p className="mb-0">
								<strong>Authenticator App</strong>
							</p>
							<p className="mb-0">
								Authenticate using a code generated by an app installed on your mobile device or
								computer.
							</p>
						</div>
					}
				/>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end align-items-center">
					<Button className="mr-4" variant="link" onClick={props.onHide}>
						Close
					</Button>
					<Button
						disabled={!mfaMethod || mfaMethod === MFA_TYPES.NOMFA}
						variant="primary"
						onClick={handleContinueButtonClick}
					>
						Continue
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
