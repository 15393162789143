import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { useRedirectUser } from '@/hooks';
import { AsyncPage, PlatformSelectionModal } from '@/components';
import Cookies from 'js-cookie';
import { COOKIES } from '@/lib/models';

function refreshCognitoUserSession(cognitoUser: CognitoUser): Promise<CognitoUserSession> {
	return new Promise((resolve, reject) => {
		cognitoUser.getSession(async (sessionError: Error | null, session: CognitoUserSession | null) => {
			if (sessionError) {
				reject(sessionError);
				return;
			}

			if (!session) {
				reject(new Error('Error getting CognitoUser session.'));
				return;
			}

			cognitoUser.refreshSession(
				session.getRefreshToken(),
				async (refreshError: Error | null, session: CognitoUserSession | null) => {
					if (refreshError) {
						reject(refreshError);
						return;
					}

					if (session) {
						resolve(session);
						return;
					}

					reject(new Error('Error refreshing CognitoUser token.'));
					return;
				}
			);
		});
	});
}

export const Landing = () => {
	const redirectUser = useRedirectUser();
	const history = useHistory();
	const [user, setUser] = useState<CognitoUser>();
	const [userEmail, setUserEmail] = useState('N/A');
	const [showPlatformSelectionModal, setShowPlatformSelectionModal] = useState(false);

	const fetchData = useCallback(async () => {
		const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
		await refreshCognitoUserSession(currentUser);

		const userAttributes = await Auth.userAttributes(currentUser);
		const emailAttributeValue = userAttributes.find((cognitoUserAttribute) => {
			return cognitoUserAttribute.Name === 'email';
		})?.Value;

		if (emailAttributeValue) {
			setUserEmail(emailAttributeValue);
		}

		setUser(currentUser);
	}, []);

	return (
		<AsyncPage fetchData={fetchData}>
			{user && (
				<PlatformSelectionModal
					user={user}
					show={showPlatformSelectionModal}
					onHide={() => {
						setShowPlatformSelectionModal(false);
					}}
				/>
			)}

			<Container className="py-6">
				<Row>
					<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
						<Card>
							<Card.Header>
								<Card.Title>Already Authenticated</Card.Title>
							</Card.Header>
							<Card.Body>
								<p className="mb-5">
									You are signed in as <span className="bold">{userEmail}</span>, do you want to
									continue?
								</p>
								<div className="text-right">
									<Button
										variant="link"
										className="mr-4"
										onClick={async () => {
											await Auth.signOut();
											history.push('/sign-in');
											Cookies.remove(COOKIES.ACCESS_TOKEN);
											Cookies.remove(COOKIES.REDIRECT_URL);
										}}
									>
										Sign Out and Change User
									</Button>
									{user && (
										<Button
											onClick={() => {
												const jwtToken = user
													.getSignInUserSession()
													?.getIdToken()
													.getJwtToken();

												try {
													redirectUser(jwtToken);
												} catch (_error) {
													setShowPlatformSelectionModal(true);
												}
											}}
										>
											Continue
										</Button>
									)}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
