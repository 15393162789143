import { ThemeConfig } from '@/contexts';

export const card = (theme: ThemeConfig) => ({
	'.hackett-card': {
		borderRadius: 2,
		backgroundColor: theme.colors.white,
		boxShadow: '0 1px 2px 0 rgba(91,103,112,0.1), 0 2px 5px 0 rgba(91,103,112,0.1)',
		'&__header': {
			padding: '20px 30px',
			borderBottom: `1px solid ${theme.colors.gray300}`,
		},
		'&__title': {
			margin: 0,
			...theme.fonts.m,
			letterSpacing: '0.025rem',
			...theme.fonts.openSansBold,
		},
		'&__body': {
			padding: 30,
		},
	},
});
