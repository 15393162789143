import { httpSingleton } from '@/lib/http-client';

export const accountService = {
	verifySignIn(jwtToken: string) {
		return httpSingleton.orchestrateRequest<void>({
			method: 'POST',
			url: `/auth-service/api/v1/account/sign-in`,
			headers: {"Authorization": "Bearer " + jwtToken}
		});
	},
};
