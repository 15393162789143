import React, { FC, useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';

import { isErrorConfig } from '@/lib/utils';
import { ErrorConfig, ERROR_CODES } from '@/lib/http-client';
import { usePlatformsContext } from '@/hooks';
import { ErrorDisplay, Loader } from '@/components';

enum DISPLAY_STATES {
	LOADING = 'LOADING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

interface AsyncPageProps {
	fetchData(): Promise<void>;
	abortFetch?(): void;
}

export const AsyncPage: FC<AsyncPageProps> = ({ children, fetchData, abortFetch }) => {
	const [fetchPageDataError, setFetchPageDataError] = useState<ErrorConfig | unknown>(undefined);
	const [displayState, setDisplayState] = useState(DISPLAY_STATES.LOADING);
	const { platforms } = usePlatformsContext();
	const platform = platforms?.[0];

	const fetchPageData = useCallback(async () => {
		setDisplayState(DISPLAY_STATES.LOADING);
		setFetchPageDataError(undefined);

		try {
			await fetchData();
			setDisplayState(DISPLAY_STATES.SUCCESS);
		} catch (error) {
			const searchParams = new URLSearchParams(window.location.search);

			if (platform) {
				searchParams.set('platform', platform.platformId);
			}

			const searchParamString = searchParams.toString();
			const signInUrl = searchParamString ? `/sign-in?${searchParamString}` : '/sign-in';

			if (isErrorConfig(error)) {
				if (error.code === ERROR_CODES.REQUEST_ABORTED) {
					return;
				}

				if (error.code === 'INVITE_ACCEPTED') {
					window.location.href = signInUrl;
					return;
				}

				if (
					error.code === 'AUTHENTICATION_REQUIRED' ||
					error.code === 'NotAuthorizedException' ||
					error.status === 401
				) {
					window.location.href = signInUrl;
					return;
				}
			} else {
				if (
					(error as any).code === 'UserNotFoundException' ||
					(error as any).name === 'UserNotFoundException'
				) {
					try {
						await Auth.signOut();
						window.location.href = signInUrl;
						return;
					} catch (error) {
						setFetchPageDataError(error);
						setDisplayState(DISPLAY_STATES.ERROR);
						return;
					}
				}

				if (error === 'The user is not authenticated') {
					window.location.href = signInUrl;
					return;
				}
			}

			setFetchPageDataError(error);
			setDisplayState(DISPLAY_STATES.ERROR);
		}
	}, [fetchData, platform]);

	useEffect(() => {
		fetchPageData();

		return () => {
			typeof abortFetch === 'function' && abortFetch();
		};
	}, [fetchPageData, abortFetch]);

	function getDisplayState() {
		switch (displayState) {
			case DISPLAY_STATES.LOADING:
				return (
					<div className="pt-20 d-flex justify-content-center align-items-center">
						<Loader />
					</div>
				);
			case DISPLAY_STATES.SUCCESS:
				return children;
			case DISPLAY_STATES.ERROR:
				return (
					<ErrorDisplay
						error={fetchPageDataError}
						showBackButton={true}
						showRetryButton={true}
						onRetryButtonClick={fetchPageData}
					/>
				);
			default:
				return <></>;
		}
	}

	return <>{getDisplayState()}</>;
};
