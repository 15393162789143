import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { isErrorConfig } from '@/lib/utils';
import { ERROR_CODES } from '@/lib/http-client';
import { useAlert } from '@/hooks';

export function useHandleError() {
	const history = useHistory();
	const { showAlert } = useAlert();

	const handleError = useCallback(
		(error: unknown) => {
			if (isErrorConfig(error)) {
				if (error.code === ERROR_CODES.REQUEST_ABORTED) {
					return;
				}

				showAlert({
					variant: 'danger',
					children: () => {
						return (
							<p className="mb-0 text-white">
								<strong>Error:</strong> {error.message}
							</p>
						);
					},
				});

				if (
					error.code === 'AUTHENTICATION_REQUIRED' ||
					error.code === 'NotAuthorizedException' ||
					error.status === 401
				) {
					return history.replace('/sign-in');
				}
			} else {
				window.alert(error);
			}
		},
		[history, showAlert]
	);

	return handleError;
}
