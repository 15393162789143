import { useMemo } from 'react';
import { usePlatformsContext } from './use-platforms';
import Cookies from 'js-cookie';
import { COOKIES } from '@/lib/models';
import { constructUrl } from '@/lib/utils';

export const useRedirectUser = () => {
	const { platforms } = usePlatformsContext();
	const platform = useMemo(() => platforms[0], [platforms]);

	const redirectUser = (accessToken?: string) => {
		const redirectUrl = Cookies.get(COOKIES.REDIRECT_URL);

		if (platform.signOnUrl && redirectUrl) {
			const soUrl = new URL(platform.signOnUrl);
			const rdUrl = new URL(redirectUrl);
			const searchParams = new URLSearchParams(rdUrl.search);

			if (accessToken) {
				searchParams.set('X-Hackett-Access-Token', accessToken);
			}

			window.location.href = constructUrl(`${soUrl.origin}${rdUrl.pathname}`, Object.fromEntries(searchParams));
			return;
		}

		if (platform.signOnUrl && !redirectUrl) {
			const url = new URL(platform.signOnUrl);
			const searchParams = new URLSearchParams(url.search);

			if (accessToken) {
				searchParams.set('X-Hackett-Access-Token', accessToken);
			}

			window.location.href = constructUrl(`${url.origin}${url.pathname}`, Object.fromEntries(searchParams));
			return;
		}

		if (!platform.signOnUrl && redirectUrl) {
			const url = new URL(redirectUrl);
			const searchParams = new URLSearchParams(url.search);

			if (accessToken) {
				searchParams.set('X-Hackett-Access-Token', accessToken);
			}

			window.location.href = constructUrl(`${url.origin}${url.pathname}`, Object.fromEntries(searchParams));
			return;
		}

		throw new Error('platform.signOnUrl and redirectUrl are undefined.');
	};

	return redirectUser;
};
