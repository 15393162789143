import React, { useCallback, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import QRCode from 'qrcode.react';

import { MFA_TYPES } from '@/lib/models';
import { useHandleError } from '@/hooks';
import { LoadingButton } from '@/components/loading-button';
import { FadeTransition } from './fade-transition';

interface MfaTotpSetupModalProps extends ModalProps {
	user: CognitoUser;
	onBack(): void;
	onSuccess(cognitoUserSession: CognitoUserSession): void;
}

export const MfaTotpSetupModal = ({ user, onBack, onSuccess, ...props }: MfaTotpSetupModalProps) => {
	const handleError = useHandleError();
	const [isLoading, setIsLoading] = useState(false);
	const [code, setCode] = useState<string>();
	const [isShowingSecretKey, setIsShowingSecretKey] = useState(false);
	const [secretKey, setSecretKey] = useState('');
	const [challengeAnswer, setChallengeAnswer] = useState('');

	const handleOnEnter = useCallback(async () => {
		setCode('');
		setIsShowingSecretKey(false);
		setSecretKey('');
		setChallengeAnswer('');

		try {
			setIsLoading(true);

			const setupCode = await Auth.setupTOTP(user);

			setSecretKey(setupCode);
			setCode(`otpauth://totp/AWSCognito:${user.getUsername()}?secret=${setupCode}&issuer=Hackett%20Advisory`);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}, [handleError, user]);

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);

			const cognitoUserSession = await Auth.verifyTotpToken(user, challengeAnswer);
			await Auth.setPreferredMFA(user, MFA_TYPES.TOTP);

			onSuccess(cognitoUserSession);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal centered {...props} onEnter={handleOnEnter}>
			<Modal.Header>
				<Modal.Title>Set up Authenticator App MFA</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleFormSubmit}>
				<Modal.Body>
					<p className="mb-0">
						<strong>Step 1:</strong>
					</p>
					<p className="mb-4">Install an authenticator app on your mobile device.</p>

					<p className="mb-0">
						<strong>Step 2:</strong>
					</p>

					<div className="mb-6">
						<p className="mb-0">
							Scan this QR Code using your authenticator app. Alternatively, you can manually enter a
							secret key in your authenticator app.{' '}
							<Button
								variant="link"
								onClick={() => {
									setIsShowingSecretKey(!isShowingSecretKey);
								}}
							>
								{isShowingSecretKey ? 'Hide' : 'Show'} Secret Key
							</Button>
						</p>
						<FadeTransition in={isShowingSecretKey}>
							<h5 className="pt-2 mb-0 text-success">{secretKey}</h5>
						</FadeTransition>
					</div>

					<div className="mb-4 d-flex flex-column align-items-center">
						<FadeTransition in={!!code}>
							<QRCode value={code ?? ''} />
						</FadeTransition>
					</div>
					<Form.Group className="mb-0">
						<p className="mb-0">
							<strong>Step 3:</strong>
						</p>
						<p>Enter a code from your authenticator app.</p>
						<Form.Control
							type="tel"
							value={challengeAnswer}
							onChange={(event) => setChallengeAnswer(event.target.value)}
							required
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex justify-content-end align-items-center">
						<Button className="mr-4" variant="link" onClick={onBack}>
							Back
						</Button>
						<LoadingButton
							isLoading={isLoading}
							disabled={!challengeAnswer}
							variant="primary"
							type="submit"
						>
							Save
						</LoadingButton>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
