import React, { useMemo } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { handleDangerousHtmlClick } from '@/lib/utils';
import { usePlatformsContext } from '@/hooks';

interface UpdateCredentialsModalProps extends ModalProps {
	onClose(): void;
}

export const UpdateCredentialsModal = ({ onClose, ...props }: UpdateCredentialsModalProps) => {
	const { platforms } = usePlatformsContext();
	const platform = useMemo(() => platforms[0], [platforms]);

	return (
		<Modal
			{...props}
			centered
			onHide={() => {
				return;
			}}
		>
			<Modal.Header>
				<Modal.Title>Update Credentials</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					dangerouslySetInnerHTML={{ __html: platform?.ctaHtmlBottom ?? '' }}
					onClick={handleDangerousHtmlClick}
				/>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end align-items-center">
					<Button variant="link" onClick={onClose}>
						Close
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
