import React, { FC, createContext, useState } from 'react';

import { PlatformModel } from '@/lib/models';

interface PlatformsContextConfig {
	platforms: PlatformModel[];
	setPlatforms: React.Dispatch<React.SetStateAction<PlatformModel[]>>;
}

export const PlatformsContext = createContext({} as PlatformsContextConfig);

export const PlatformsProvider: FC = ({ children }) => {
	const [platforms, setPlatforms] = useState<PlatformModel[]>([]);

	const value = {
		platforms,
		setPlatforms,
	};

	return <PlatformsContext.Provider value={value}>{children}</PlatformsContext.Provider>;
};
