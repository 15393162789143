import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';

import { RenderJson } from '@/components';

interface ErrorDisplayProps {
	error: any;
	showBackButton?: boolean;
	showRetryButton?: boolean;

	onRetryButtonClick?(): void;
}

export const ErrorDisplay = ({ error, showBackButton, showRetryButton, onRetryButtonClick }: ErrorDisplayProps) => {
	const history = useHistory();

	function handleGoBackButtonClick() {
		history.goBack();
	}

	return (
		<>
			<Container className="py-10">
				<Row>
					<Col>
						<h3 className="mb-2 text-center text-blue-one">Error</h3>
						<p className="mb-4 text-center">{error.message}</p>
						<div className="d-flex justify-content-center">
							{showBackButton && (
								<Button onClick={handleGoBackButtonClick} className="mr-2">
									Go back
								</Button>
							)}
							{showRetryButton && <Button onClick={onRetryButtonClick}>Retry</Button>}
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<RenderJson json={error} />
					</Col>
				</Row>
			</Container>
		</>
	);
};
