import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Auth, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core';
import { CognitoUser } from '@aws-amplify/auth';

import { handleDangerousHtmlClick } from '@/lib/utils';
import config from '@/lib/config';
import { CHALLENGE_NAMES, COOKIES } from '@/lib/models';
import { accountService, publicService } from '@/lib/services';
import {
	createUseThemedStyles,
	useAutoSignInContext,
	useHandleError,
	usePlatformsContext,
	useRedirectUser,
} from '@/hooks';
import {
	LoadingButton,
	MfaCodeEntryModal,
	NewPasswordRequiredModal,
	MfaFlowModals,
	ExpandingCarousel,
	PlatformSelectionModal,
	UpdateCredentialsModal,
} from '@/components';
import { mediaQueries } from '@/jss';

import { ReactComponent as HackettLogo } from '@/assets/logos/logo-hackett-r.svg';

import aiBg from '@/assets/images/ai-bg.png';
import aiXplrBg from '@/assets/images/ai-xplr-bg.png';
import aiXplrSlide from '@/assets/images/ai-xplr-logo.png';

const useStyles = createUseThemedStyles((theme) => ({
	hackettConnectLogo: {
		width: '75%',
		height: 'auto',
	},
	logInFormOuter: {
		width: '90%',
		margin: '0 auto',
		[mediaQueries.md]: {
			width: '60%',
			maxWidth: 540,
		},
		[mediaQueries.xl]: {
			width: '75%',
		},
	},
	carouselOuter: {
		display: 'none',
		[mediaQueries.xl]: {
			width: '100%',
			height: '100%',
			minHeight: 750,
			display: 'block',
		},
	},
	logInForm: {
		padding: 25,
		marginBottom: 10,
		backgroundColor: theme.colors.gray100,
		border: `1px solid ${theme.colors.gray300}`,
	},
	hackettGroupLogo: {
		width: '75%',
	},
	'@global': {
		body: {
			backgroundColor: theme.colors.white,
		},
	},
}));

export const SignIn = () => {
	const redirectUser = useRedirectUser();
	const classes = useStyles();
	const handleError = useHandleError();
	const { autoSignInUser, setAutoSignInUser, setEmailAddress, setPassword } = useAutoSignInContext();

	const [isLoading, setIsLoading] = useState(false);
	const [emailInputValue, setEmailInputValue] = useState<string>('');
	const [passwordInputValue, setPasswordInputValue] = useState<string>('');
	const [user, setUser] = useState<CognitoUser>();

	const [isFirstTimeSetup, setIsFirstTimeSetup] = useState(false);
	const [showMfaFlow, setShowMfaFlow] = useState(false);
	const [showNewPasswordRequiredModal, setShowNewPasswordRequiredModal] = useState(false);
	const [showMfaCodeEntryModal, setShowMfaCodeEntryModal] = useState(false);
	const [showUpdateCredentialsModal, setShowUpdateCredentialsModal] = useState(false);

	const [platformSelectionUser, setPlatformSelectionUser] = useState<CognitoUser>();
	const [showPlatformSelectionModal, setShowPlatformSelectionModal] = useState(false);
	const { platforms } = usePlatformsContext();
	const platform = useMemo(() => platforms[0], [platforms]);

	useEffect(() => {
		if (Cookies.get(COOKIES.UPDATE_CREDENTIALS_LINK_CLICKED)) {
			return;
		}

		setShowUpdateCredentialsModal(true);
	}, []);

	async function handleSignInFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);

			if (config.HACKETT_USER_AUTH_USE_REAL_AUTH === 'true') {
				const cognitoUser: CognitoUser = await Auth.signIn(
					emailInputValue.replace(' ', '').trim(),
					passwordInputValue
				);
				const challengesCleared = cognitoUserHasClearedChallenges(cognitoUser);

				// If challengesCleared is true, the Hub will handle the signIn redirect.
				// If not, we still need to set the user state to pass to the modals as a prop.
				if (!challengesCleared) {
					setUser(cognitoUser);
					setIsLoading(false);
				}
			} else {
				const { token } = await publicService.authenticate({ emailAddress: emailInputValue }).fetch();
				redirectUser(token);
			}
		} catch (error) {
			setIsLoading(false);

			if (
				((error as any).code === 'NotAuthorizedException' ||
					(error as any).name === 'NotAuthorizedException') &&
				(error as any).message === 'Password attempts exceeded'
			) {
				setShowUpdateCredentialsModal(true);
				return;
			}

			handleError(error);
		}
	}

	// Check user challenges and open appropriate modals.
	const cognitoUserHasClearedChallenges = useCallback((cognitoUser: CognitoUser) => {
		setIsFirstTimeSetup(false);

		if (!cognitoUser.challengeName) {
			return true;
		}

		switch (cognitoUser.challengeName) {
			case CHALLENGE_NAMES.SELECT_MFA_TYPE:
			case CHALLENGE_NAMES.MFA_SETUP:
				setIsFirstTimeSetup(true);
				setShowMfaFlow(true);
				return false;
			case CHALLENGE_NAMES.SMS_MFA:
			case CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA:
				setShowMfaCodeEntryModal(true);
				return false;
			case CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED:
				setShowNewPasswordRequiredModal(true);
				return false;
			case CHALLENGE_NAMES.CUSTOM_CHALLENGE:
			default:
				return true;
		}
	}, []);

	// If we have an automatically signed in user from the accept-invite.tsx page,
	// check if their challenges have been cleared, and assign data accordingly.
	useEffect(() => {
		if (!autoSignInUser) {
			return;
		}

		const challengesCleared = cognitoUserHasClearedChallenges(autoSignInUser);

		// If challengesCleared is true, the Hub will handle the signIn redirect.
		// If not, we still need to set the user state to pass to the modals as a prop.
		if (!challengesCleared) {
			setUser(autoSignInUser);
			setAutoSignInUser(undefined);
		}
	}, [autoSignInUser, cognitoUserHasClearedChallenges, setAutoSignInUser]);

	// AWS Amplify Hub handles listening to 'auth' events.
	// https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
	useEffect(() => {
		const handleHubAuthEvent = async ({ payload }: HubCapsule) => {
			const { event, data } = payload;

			switch (event) {
				// If a 'signIn' event is detected, either from sign in, code entry, or MFA clearance,
				// extract the jwtToken from the payload and redirect the user to their destination.
				// Also clear out all context data just in case.
				case 'signIn':
					const jwtToken = data
						.getSignInUserSession()
						?.getIdToken()
						.getJwtToken();

					setAutoSignInUser(undefined);
					setEmailAddress('');
					setPassword('');
					setEmailInputValue('');
					setPasswordInputValue('');

					try {
						await accountService.verifySignIn(jwtToken).fetch();
					} catch (error) {
						//do not report.
					} finally {
						try {
							redirectUser(jwtToken);
						} catch (_error) {
							setPlatformSelectionUser(data);
							setShowPlatformSelectionModal(true);
						}
					}
					break;
			}
		};

		Hub.listen('auth', handleHubAuthEvent);
		return () => {
			Hub.remove('auth', handleHubAuthEvent);
		};
	}, [platform, redirectUser, setAutoSignInUser, setEmailAddress, setPassword]);

	return (
		<>
			{user && (
				<>
					<MfaFlowModals
						user={user}
						show={showMfaFlow}
						firstTimeSetup={isFirstTimeSetup}
						onHide={() => {
							setShowMfaFlow(false);
						}}
						onComplete={() => {
							setShowMfaFlow(false);
							// Auth Hub will handle successful sign in
						}}
					/>

					<NewPasswordRequiredModal
						user={user}
						show={showNewPasswordRequiredModal}
						onHide={() => {
							setShowNewPasswordRequiredModal(false);
						}}
						onSuccess={(loggedUser) => {
							setShowNewPasswordRequiredModal(false);
							setPassword('');
							setPasswordInputValue('');
							// Auth Hub will handle successful sign in
						}}
					/>

					<MfaCodeEntryModal
						user={user}
						show={showMfaCodeEntryModal}
						onHide={() => {
							setShowMfaCodeEntryModal(false);
						}}
						onSuccess={() => {
							setShowMfaCodeEntryModal(false);
							// Auth Hub will handle successful sign in
						}}
					/>
				</>
			)}

			{platformSelectionUser && (
				<PlatformSelectionModal
					user={platformSelectionUser}
					show={showPlatformSelectionModal}
					onHide={() => {
						setShowPlatformSelectionModal(false);
					}}
				/>
			)}

			{platform?.ctaHtmlBottom && (
				<UpdateCredentialsModal
					show={showUpdateCredentialsModal}
					onClose={() => {
						setShowUpdateCredentialsModal(false);
					}}
				/>
			)}

			<Container>
				<Row>
					<Col xl={6} className="py-11">
						<div className={classes.logInFormOuter}>
							<h1 className="mb-6 text-primary text-center">
								{platform?.headerIconUrl && (
									<img
										src={platform.headerIconUrl}
										alt={platform.platformName}
										style={{ margin: '0 auto', maxWidth: '75%' }}
									/>
								)}
							</h1>
							<Form className={classes.logInForm} onSubmit={handleSignInFormSubmit}>
								<h1 className="mb-2 text-secondary">Log in</h1>
								<div dangerouslySetInnerHTML={{ __html: platform?.ctaHtmlTop ?? '' }} />
								<div
									dangerouslySetInnerHTML={{ __html: platform?.ctaHtmlBottom ?? '' }}
									onClick={handleDangerousHtmlClick}
								/>

								<Form.Group className="mb-4" controlId="sign-in-email">
									<Form.Label>Email</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter your email"
										value={emailInputValue}
										onChange={({ currentTarget }) => {
											setEmailAddress(currentTarget.value);
											setEmailInputValue(currentTarget.value);
										}}
										required
									/>
								</Form.Group>
								<Form.Group className="mb-4" controlId="sign-in-password">
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										value={passwordInputValue}
										onChange={({ currentTarget }) => {
											setPassword(currentTarget.value);
											setPasswordInputValue(currentTarget.value);
										}}
										required={config.HACKETT_USER_AUTH_USE_REAL_AUTH === 'true'}
									/>
								</Form.Group>
								<div className="mb-4 text-right">
									<Link to="/forgot-password">Forgot password?</Link>
								</div>
								<div>
									<LoadingButton
										isLoading={isLoading}
										variant="success"
										type="submit"
										className="w-100 d-block"
									>
										Log In
									</LoadingButton>
								</div>
							</Form>
							<h1 className="text-center">
								<HackettLogo className={classes.hackettGroupLogo} />
							</h1>
						</div>
					</Col>
					<Col xl={6}>
						<div className={classes.carouselOuter}>
							<ExpandingCarousel
								slides={[
									{
										imageUrl: aiBg,
										htmlContent: `
											<h1 class="mb-40 text-white text-center font-weight-bold">The Hackett Group<sup>&reg;</sup><br/>Acquires LeewayHertz</h1>
											<h3 class="text-white text-center font-weight-normal">
												Offers complete, end-to-end<br/>AI capabilities from idea to impact.
											</h3>
										`,
										action: {
											variant: 'info',
											title: 'Learn More',
											onClick: () => {
												window.open(
													'https://www.thehackettgroup.com/the-hackett-group-announces-strategic-acquisition-of-leading-gen-ai-development-firm-leewayhertz/',
													'_blank',
													'noopener'
												);
											},
										},
										slideContentStyle: { transform: 'translateY(20px)' },
									},
									{
										imageUrl: aiXplrBg,
										htmlContent: `
											<h1 class="mb-2 text-white text-center">Define Your Gen AI Journey with The Hackett Group's AI XPLR™ Platform</h1>
											<img src="${aiXplrSlide}" style="max-width: 60%; display: block; margin: 0 auto;" alt="" />
										`,
										action: {
											variant: 'dark',
											title: 'Schedule Demo',
											onClick: () => {
												window.open(
													'https://go.poweredbyhackett.com/conusaixplr2402hc',
													'_blank',
													'noopener'
												);
											},
										},
									},
								]}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};
