import React, { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { Loader } from '@/components';

const useStyles = createUseThemedStyles({
	loader: {
		top: '50%',
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
	},
	children: ({ isLoading }: { isLoading: boolean }) => ({
		opacity: isLoading ? 0 : 1,
	}),
});

interface LoadingButtonProps extends ButtonProps {
	isLoading?: boolean;
}

export const LoadingButton: FC<LoadingButtonProps> = ({ isLoading, className, onClick, children, ...props }) => {
	const classes = useStyles({ isLoading });

	return (
		<Button
			className={classNames('position-relative', className)}
			onClick={(event) => {
				if (isLoading) {
					event.preventDefault();
				}

				if (onClick) {
					onClick(event);
				}
			}}
			{...props}
		>
			{isLoading && <Loader size={24} className={classes.loader} />}
			<span className={classes.children}>{children}</span>
		</Button>
	);
};
