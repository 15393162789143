import { ThemeConfig } from '@/contexts';

export const badge = (theme: ThemeConfig) => ({
	'.hackett-badge': {
		...theme.fonts.s,
		padding: '1.2rem 2rem',
		display: 'inline-flex',
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.gray400}`,
		'&-pill': {
			borderRadius: 500,
		},
		'&-primary': {
			color: theme.colors.white,
			backgroundColor: theme.colors.primary,
			border: `1px solid ${theme.colors.primary}`,
		},
	},
});
