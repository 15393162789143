import React, { FC, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

const useStyles = createUseStyles({
	fadeTransitionOuter: ({ animationDuration }: { animationDuration: number }) => ({
		overflow: 'hidden',
		transition: `height ${animationDuration}ms ease-out`,
	}),
	opacityHeightTransition: ({ animationDuration }: { animationDuration: number }) => ({
		'&-enter': {
			opacity: 0,
		},
		'&-enter-active': {
			opacity: 1,
			transition: `opacity ${animationDuration}ms ease-out`,
		},
		'&-exit': {
			opacity: 1,
		},
		'&-exit-active': {
			opacity: 0,
			transition: `opacity ${animationDuration}ms ease-out`,
		},
	}),
});

type FadeTransitionProps = Partial<CSSTransitionProps> & {
	animationDuration?: number;
};

export const FadeTransition: FC<FadeTransitionProps> = ({ animationDuration = 200, children, ...props }) => {
	const outerRef = useRef<HTMLDivElement | null>(null);
	const innerRef = useRef<HTMLDivElement | null>(null);
	const classes = useStyles({ animationDuration });

	function handleEnter() {
		if (outerRef.current) {
			outerRef.current.style.height = '0px';
		}
	}

	function handleEntering() {
		const targetHeight = innerRef.current?.getBoundingClientRect().height;

		if (outerRef.current) {
			outerRef.current.style.height = `${targetHeight}px`;
		}
	}

	function handleEntered() {
		if (outerRef.current) {
			outerRef.current.style.removeProperty('height');
		}
	}

	function handleExit() {
		const targetHeight = innerRef.current?.getBoundingClientRect().height;

		if (outerRef.current) {
			outerRef.current.style.height = `${targetHeight}px`;
		}
	}

	function handleExiting() {
		if (outerRef.current) {
			outerRef.current.style.height = '0px';
		}
	}

	function handleExited() {
		if (outerRef.current) {
			outerRef.current.style.removeProperty('height');
		}
	}

	return (
		<div className={classes.fadeTransitionOuter} ref={outerRef}>
			<CSSTransition
				{...props}
				timeout={animationDuration}
				mountOnEnter={true}
				unmountOnExit={true}
				classNames={classes.opacityHeightTransition}
				onEnter={handleEnter}
				onEntering={handleEntering}
				onEntered={handleEntered}
				onExit={handleExit}
				onExiting={handleExiting}
				onExited={handleExited}
			>
				<div ref={innerRef}>{children}</div>
			</CSSTransition>
		</div>
	);
};
