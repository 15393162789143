import React, { useCallback, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { E164Number } from 'libphonenumber-js/core';
import classNames from 'classnames';

import { useHandleError } from '@/hooks';
import { LoadingButton, PhoneNumberInput } from '@/components';

interface NewPasswordRequiredModalProps extends ModalProps {
	user: CognitoUser;
	onSuccess(user: CognitoUser): void;
}

export const NewPasswordRequiredModal = ({ user, onSuccess, ...props }: NewPasswordRequiredModalProps) => {
	const handleError = useHandleError();
	const [isLoading, setIsLoading] = useState(false);
	const [newPasswordInputValue, setNewPasswordInputValue] = useState('');
	const [phoneNumberInputValue, setPhoneNumberInputValue] = useState<E164Number>();
	const [requiresPhoneNumber, setRequiresPhoneNumber] = useState(false);

	const handleOnEnter = useCallback(() => {
		setIsLoading(false);
		setNewPasswordInputValue('');
		setPhoneNumberInputValue(undefined);

		// challengeParam property is missing on CognitoUser TS definition
		// @ts-ignore
		if (user.challengeParam?.requiredAttributes?.includes('phone_number')) {
			setRequiresPhoneNumber(true);
		} else {
			setRequiresPhoneNumber(false);
		}
	}, [user]);

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setIsLoading(true);

		try {
			const loggedUser: CognitoUser = await Auth.completeNewPassword(user, newPasswordInputValue, {
				...(requiresPhoneNumber && { phone_number: phoneNumberInputValue }),
			});
			onSuccess(loggedUser);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Modal centered onEnter={handleOnEnter} {...props}>
			<Modal.Header>
				<Modal.Title>New Password Required</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleFormSubmit}>
				<Modal.Body>
					<Form.Group
						className={classNames({
							'mb-0': !requiresPhoneNumber,
						})}
					>
						<Form.Label>New Password</Form.Label>
						<Form.Control
							type="password"
							value={newPasswordInputValue}
							onChange={(event) => setNewPasswordInputValue(event.target.value)}
							required
						/>
					</Form.Group>
					{requiresPhoneNumber && (
						<Form.Group className="mb-0">
							<Form.Label>Phone Number</Form.Label>
							<PhoneNumberInput
								value={phoneNumberInputValue}
								onChange={setPhoneNumberInputValue}
								required
							/>
						</Form.Group>
					)}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex justify-content-end align-items-center">
						<Button className="mr-4" variant="link" onClick={props.onHide}>
							Close
						</Button>
						<LoadingButton
							isLoading={isLoading}
							disabled={!newPasswordInputValue || (requiresPhoneNumber && !phoneNumberInputValue)}
							variant="primary"
							type="submit"
						>
							Save
						</LoadingButton>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
