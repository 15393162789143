import React, { useEffect, useState } from 'react';
import { CognitoUser } from '@aws-amplify/auth';

import { MfaChoiceModal, MfaSmsSetupModal, MfaTotpSetupModal } from '@/components';
import { useHandleError } from '@/hooks';
import { CHALLENGE_NAMES, PREFERRED_MFA_TYPES } from '@/lib/models';

interface Props {
	show: boolean;
	onHide(): void;
	user: CognitoUser;
	firstTimeSetup: boolean;
	onComplete(): void;
}

export const MfaFlowModals = ({ show, user, firstTimeSetup, onHide, onComplete }: Props) => {
	const handleError = useHandleError();

	const [showMfaChoiceModal, setShowMfaChoiceModal] = useState(false);
	const [showMfaTotpSetupModal, setShowMfaTotpSetupModal] = useState(false);
	const [showMfaSmsSetupModal, setShowMfaSmsSetupModal] = useState(false);

	const resetFlow = () => {
		setShowMfaChoiceModal(true);
		setShowMfaTotpSetupModal(false);
		setShowMfaSmsSetupModal(false);
	};

	useEffect(() => {
		if (show) {
			resetFlow();
		} else {
			setShowMfaChoiceModal(false);
			setShowMfaTotpSetupModal(false);
			setShowMfaSmsSetupModal(false);
		}
	}, [show, user]);

	return (
		<>
			<MfaChoiceModal
				show={showMfaChoiceModal}
				onHide={() => {
					onHide();
				}}
				onTotpClick={() => {
					if (user.challengeName === CHALLENGE_NAMES.SELECT_MFA_TYPE) {
						user.sendMFASelectionAnswer(PREFERRED_MFA_TYPES.SOFTWARE_TOKEN_MFA, {
							onSuccess: (_session) => {
								setShowMfaChoiceModal(false);
								setShowMfaTotpSetupModal(true);
							},
							onFailure: (error) => {
								handleError(error);
							},
						});
					} else {
						setShowMfaChoiceModal(false);
						setShowMfaTotpSetupModal(true);
					}
				}}
				onSmsClick={() => {
					if (user.challengeName === CHALLENGE_NAMES.SELECT_MFA_TYPE) {
						user.sendMFASelectionAnswer(PREFERRED_MFA_TYPES.SMS_MFA, {
							onSuccess: (_session) => {
								setShowMfaChoiceModal(false);
								setShowMfaSmsSetupModal(true);
							},
							onFailure: (error) => {
								handleError(error);
							},
						});
					} else {
						setShowMfaChoiceModal(false);
						setShowMfaSmsSetupModal(true);
					}
				}}
			/>

			<MfaTotpSetupModal
				user={user}
				show={showMfaTotpSetupModal}
				onBack={() => {
					resetFlow();
				}}
				onHide={() => {
					onHide();
				}}
				onSuccess={() => {
					onComplete();
				}}
			/>

			<MfaSmsSetupModal
				username={user.getUsername()}
				firstTimeSetup={firstTimeSetup}
				show={showMfaSmsSetupModal}
				onBack={() => {
					resetFlow();
				}}
				onHide={() => {
					onHide();
				}}
				onSuccess={() => {
					onComplete();
				}}
			/>
		</>
	);
};
