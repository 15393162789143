import { httpSingleton } from '@/lib/http-client';
import { AccountInviteModel, PlatformModel } from '@/lib/models';

export const publicService = {
	authenticate(data: { emailAddress: string }) {
		return httpSingleton.orchestrateRequest<{
			token: string;
		}>({
			method: 'POST',
			url: `/auth-service/api/v1/public/authenticate`,
			data,
		});
	},
	createInvite(data: {
		emailAddress: string;
		name: string;
		redirectUrl: string;
		expirationDays: number;
		suppressInvite?: boolean;
	}) {
		return httpSingleton.orchestrateRequest<{
			inviteToken: string;
		}>({
			method: 'POST',
			url: `/auth-service/api/v1/public/invite`,
			data,
		});
	},
	getInvite(inviteId: string) {
		return httpSingleton.orchestrateRequest<AccountInviteModel>({
			method: 'GET',
			url: `/auth-service/api/v1/public/invite/${inviteId}`,
		});
	},
	accountInvite(inviteId: string) {
		return httpSingleton.orchestrateRequest<AccountInviteModel>({
			method: 'POST',
			url: `/auth-service/api/v1/public/invite/${inviteId}/accept`,
		});
	},
	updateAndVerifyUserPhoneNumber(data: { username: string; phoneNumber: string }) {
		return httpSingleton.orchestrateRequest<any>({
			method: 'POST',
			url: `/auth-service/api/v1/public/phone-number`,
			data,
		});
	},
	verifyUserPhoneNumberSubmit(data: { username: string; clientId: string; code: string }) {
		return httpSingleton.orchestrateRequest<any>({
			method: 'POST',
			url: `/auth-service/api/v1/public/code`,
			data,
		});
	},
	getSystemStatus() {
		return httpSingleton.orchestrateRequest<{
			version: string;
			status: string;
			date: string;
		}>({
			method: 'GET',
			url: '/auth-service/api/v1/public/system/status',
		});
	},
	getError() {
		return httpSingleton.orchestrateRequest<any>({
			method: 'GET',
			url: '/auth-service/api/v1/public/system/error',
		});
	},
	getPlatforms(data: { platform: string[] }) {
		return httpSingleton.orchestrateRequest<{
			platforms: PlatformModel[];
		}>({
			method: 'POST',
			url: '/auth-service/api/v1/public/platforms',
			data,
		});
	},
};
