import Color from 'color';

import { createUseThemedStyles } from '@/hooks';

import { badge, button, form, card, modal } from '@/jss/bootstrap';
import mediaQueries from '@/jss/media-queries';

const spacerSize: number = 5;
const iterations: number = 40;

const m: Record<string, Record<string, string>> = {};
const ml: Record<string, Record<string, string>> = {};
const mr: Record<string, Record<string, string>> = {};
const mt: Record<string, Record<string, string>> = {};
const mb: Record<string, Record<string, string>> = {};
const mx: Record<string, Record<string, string>> = {};
const my: Record<string, Record<string, string>> = {};

const p: Record<string, Record<string, string>> = {};
const pl: Record<string, Record<string, string>> = {};
const pr: Record<string, Record<string, string>> = {};
const pt: Record<string, Record<string, string>> = {};
const pb: Record<string, Record<string, string>> = {};
const px: Record<string, Record<string, string>> = {};
const py: Record<string, Record<string, string>> = {};

for (let i: number = 0; i <= iterations; i++) {
	m[`.m-${i}`] = { margin: `${spacerSize * i}px !important` };
	ml[`.ml-${i}`] = { marginLeft: `${spacerSize * i}px !important` };
	mr[`.mr-${i}`] = { marginRight: `${spacerSize * i}px !important` };
	mt[`.mt-${i}`] = { marginTop: `${spacerSize * i}px !important` };
	mb[`.mb-${i}`] = { marginBottom: `${spacerSize * i}px !important` };
	mx[`.mx-${i}`] = { marginLeft: `${spacerSize * i}px !important`, marginRight: `${spacerSize * i}px !important` };
	my[`.my-${i}`] = { marginTop: `${spacerSize * i}px !important`, marginBottom: `${spacerSize * i}px !important` };

	p[`.p-${i}`] = { padding: `${spacerSize * i}px !important` };
	pl[`.pl-${i}`] = { paddingLeft: `${spacerSize * i}px !important` };
	pr[`.pr-${i}`] = { paddingRight: `${spacerSize * i}px !important` };
	pt[`.pt-${i}`] = { paddingTop: `${spacerSize * i}px !important` };
	pb[`.pb-${i}`] = { paddingBottom: `${spacerSize * i}px !important` };
	px[`.px-${i}`] = { paddingLeft: `${spacerSize * i}px !important`, paddingRight: `${spacerSize * i}px !important` };
	py[`.py-${i}`] = { paddingTop: `${spacerSize * i}px !important`, paddingBottom: `${spacerSize * i}px !important` };
}

export const useThemedBootstrapStyles = createUseThemedStyles((theme) => ({
	'@global': {
		/* ----------------------------------------------------------- */
		/* bsPrefix overrides */
		/* ----------------------------------------------------------- */
		...badge(theme),
		...button(theme),
		...card(theme),
		...form(theme),
		...modal(theme),

		/* ----------------------------------------------------------- */
		/* Generic overrides */
		/* ----------------------------------------------------------- */
		'.container': {
			[mediaQueries.xl]: {
				maxWidth: 1440,
				width: 'calc(100% - 70px)',
			},
			[mediaQueries.lg]: {
				maxWidth: 1440,
				width: 'calc(100% - 70px)',
			},
			[mediaQueries.md]: {
				maxWidth: 1440,
				width: 'calc(100% - 70px)',
			},
			[mediaQueries.sm]: {
				maxWidth: 1440,
				width: 'calc(100% - 70px)',
			},
		},
		'.font-weight-regular': {
			...theme.fonts.openSansRegular,
		},
		'.font-weight-semi-bold': {
			...theme.fonts.openSansSemiBold,
		},
		'.font-weight-bold': {
			...theme.fonts.openSansBold,
		},
		'.font-italic': {
			fontStyle: 'italic',
		},

		/* ----------------------------------------------------------- */
		/* Spacer overrides */
		/* ----------------------------------------------------------- */
		...m,
		...ml,
		...mr,
		...mt,
		...mb,
		...mx,
		...my,
		...p,
		...pl,
		...pr,
		...pt,
		...pb,
		...px,
		...py,

		/* ----------------------------------------------------------- */
		/* Headings and text overrides */
		/* ----------------------------------------------------------- */
		'h1, .h1': {
			...theme.fonts.xl,
			...theme.fonts.openSansSemiBold,
		},
		'h2, .h2': {
			...theme.fonts.l,
			...theme.fonts.openSansSemiBold,
		},
		'h3, .h3': {
			...theme.fonts.m,
			...theme.fonts.openSansSemiBold,
		},
		'h4, .h4': {
			...theme.fonts.s,
			...theme.fonts.openSansSemiBold,
		},
		'h5, .h5': {
			...theme.fonts.xs,
			...theme.fonts.openSansSemiBold,
		},
		'h6, .h6': {
			...theme.fonts.xxs,
			...theme.fonts.openSansSemiBold,
		},
		'p, .p': {
			...theme.fonts.s,
			...theme.fonts.openSansRegular,
		},
		'small, .small': {
			...theme.fonts.xs,
		},
		'a, .a': {
			textDecoration: 'none',
			color: theme.colors.info,
			'&:hover': {
				textDecoration: 'underline',
				color: Color(theme.colors.info)
					.lighten(0.16)
					.hex(),
			},
			'&:focus': {
				outline: 'none',
				boxShadow: `0 0 0 0.2rem  ${Color(theme.colors.info)
					.alpha(0.3)
					.rgb()}`,
			},
		},
		'strong, bold, .strong, .bold': {
			...theme.fonts.openSansBold,
		},
		'.text-black': {
			color: `${theme.colors.black} !important`,
		},
		'.text-muted': {
			color: `${theme.colors.gray600} !important`,
		},
		'.text-primary': {
			color: `${theme.colors.primary} !important`,
		},
		'.text-secondary': {
			color: `${theme.colors.secondary} !important`,
		},
		'.text-success': {
			color: `${theme.colors.success} !important`,
		},
		'.text-warning': {
			color: `${theme.colors.warning} !important`,
		},
		'.text-danger': {
			color: `${theme.colors.danger} !important`,
		},
		'.text-info': {
			color: `${theme.colors.info} !important`,
		},
		'.text-dark': {
			color: `${theme.colors.dark} !important`,
		},
		'.text-gray-100': {
			color: `${theme.colors.gray100} !important`,
		},
		'.text-gray-200': {
			color: `${theme.colors.gray200} !important`,
		},
		'.text-gray-300': {
			color: `${theme.colors.gray300} !important`,
		},
		'.text-gray-400': {
			color: `${theme.colors.gray400} !important`,
		},
		'.text-gray-500': {
			color: `${theme.colors.gray500} !important`,
		},
		'.text-gray-600': {
			color: `${theme.colors.gray600} !important`,
		},
		'.text-gray-700': {
			color: `${theme.colors.gray700} !important`,
		},
		'.text-right': {
			textAlign: 'right',
		},

		/* ----------------------------------------------------------- */
		/* BG overrides */
		/* ----------------------------------------------------------- */
		'.bg-white': {
			backgroundColor: `${theme.colors.white} !important`,
		},
		'.bg-gray-100': {
			backgroundColor: `${theme.colors.gray100} !important`,
		},
		'.bg-gray-200': {
			backgroundColor: `${theme.colors.gray200} !important`,
		},
		'.bg-gray-300': {
			backgroundColor: `${theme.colors.gray300} !important`,
		},
		'.bg-primary': {
			backgroundColor: `${theme.colors.primary} !important`,
		},
		'.bg-secondary': {
			backgroundColor: `${theme.colors.secondary} !important`,
		},
		'.bg-success': {
			backgroundColor: `${theme.colors.success} !important`,
		},
		'.bg-warning': {
			backgroundColor: `${theme.colors.warning} !important`,
		},
		'.bg-danger': {
			backgroundColor: `${theme.colors.danger} !important`,
		},
		'.bg-info': {
			backgroundColor: `${theme.colors.info} !important`,
		},
		'.bg-dark': {
			backgroundColor: `${theme.colors.dark} !important`,
		},

		/* ----------------------------------------------------------- */
		/* <Tab> overrides (no bsPrefix support for default tabs) */
		/* ----------------------------------------------------------- */
		'.nav-tabs': {
			border: 0,
			overflowX: 'auto',
			flexWrap: 'nowrap',
			'& .nav-item': {
				borderTop: `1px solid ${theme.colors.gray300}`,
				borderRight: 0,
				borderBottom: 0,
				borderLeft: `1px solid ${theme.colors.gray300}`,
				'&:last-child': {
					borderRight: `1px solid ${theme.colors.gray300}`,
				},
			},
			'& .nav-link': {
				border: 0,
				borderRadius: 0,
				marginBottom: 0,
				position: 'relative',
				whiteSpace: 'nowrap',
				padding: '2.4rem 3.2rem',
				textTransform: 'uppercase',
				...theme.fonts.xs,
				...theme.fonts.openSansSemiBold,
				color: theme.colors.primary,
				'&.active': {
					color: theme.colors.primary,
					backgroundColor: theme.colors.white,
					'&:before': {
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						content: '""',
						position: 'absolute',
						pointerEvents: 'none',
						backgroundColor: Color(theme.colors.primary)
							.alpha(0.08)
							.string(),
					},
					'&:after': {
						left: 0,
						right: 0,
						bottom: 0,
						height: 2,
						content: '""',
						position: 'absolute',
						backgroundColor: theme.colors.primary,
					},
				},
			},
			'& + .tab-content': {
				padding: '2.4rem 3.2rem',
				border: `1px solid ${theme.colors.gray300}`,
			},
		},
	},
}));
