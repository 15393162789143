import React, { FC } from 'react';
import { Form } from 'react-bootstrap';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as DownChevron } from '@/assets/icons/icon-chevron-down.svg';

const useSelectStyles = createUseThemedStyles((theme) => ({
	select: {
		position: 'relative',
		'& select': {
			zIndex: 0,
			...theme.fonts.s,
			height: 40,
			width: '100%',
			borderRadius: 2,
			cursor: 'pointer',
			appearance: 'none',
			position: 'relative',
			padding: '0 40px 0 15px',
			color: theme.colors.black,
			backgroundColor: theme.colors.white,
			border: `1px solid ${theme.colors.gray500}`,
			boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
			'&:hover': {
				border: `1px solid ${theme.colors.gray600}`,
			},
			'&:focus': {
				outline: 'none',
				border: `1px solid ${theme.colors.primary}`,
			},
			'&:disabled': {
				cursor: 'default',
				color: theme.colors.gray500,
				backgroundColor: theme.colors.gray300,
				'&:hover': {
					border: `1px solid ${theme.colors.gray600}`,
				},
			},
			'&:invalid': {
				fontStyle: 'italic',
				color: theme.colors.gray600,
			},
			'&::-ms-expand': {
				display: 'none',
			},
		},
	},
	downChevron: {
		zIndex: 1,
		right: 15,
		top: '50%',
		position: 'absolute',
		pointerEvents: 'none',
		transform: 'translateY(-50%)',
		fill: theme.colors.primary,
	},
}));

interface SelectProps {
	value: string;
	onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
	required?: boolean;
}

export const Select: FC<SelectProps> = (props) => {
	const classes = useSelectStyles();

	function handleOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
		props.onChange(event);
	}

	return (
		<div className={classes.select}>
			<Form.Control
				as="select"
				value={props.value}
				onChange={handleOnChange}
				bsPrefix="hackett-select"
				required={props.required}
			>
				{props.children}
			</Form.Control>
			<DownChevron className={classes.downChevron} />
		</div>
	);
};
