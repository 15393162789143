import React, { useCallback, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

import { useAlert, useRedirectUser } from '@/hooks';
import { PREFERRED_MFA_TYPES } from '@/lib/models';
import { AsyncPage, MfaFlowModals } from '@/components';

export const ChangeMfaMethod = () => {
	const redirectUser = useRedirectUser();
	const { showAlert } = useAlert();
	const [user, setUser] = useState<CognitoUser>();
	const [preferredMfaType, setPreferredMfaType] = useState<PREFERRED_MFA_TYPES>();
	const [userPhoneNumber, setUserPhoneNumber] = useState('N/A');

	const [showMfaFlowModals, setShowMfaFlowModals] = useState(false);

	const fetchData = useCallback(async () => {
		const userResponse: CognitoUser = await Auth.currentAuthenticatedUser();
		const mfaResponse = await Auth.getPreferredMFA(userResponse, { bypassCache: true });
		const userAttributes = await Auth.userAttributes(userResponse);
		const phoneNumberAttributeValue = userAttributes.find((cognitoUserAttribute) => {
			return cognitoUserAttribute.Name === 'phone_number';
		})?.Value;

		if (phoneNumberAttributeValue) {
			setUserPhoneNumber(phoneNumberAttributeValue);
		}

		setUser(userResponse);
		setPreferredMfaType(mfaResponse as PREFERRED_MFA_TYPES);
	}, []);

	const handleChangeMfaMethodButtonClick = () => {
		setShowMfaFlowModals(true);
	};

	const handleMfaFlowComplete = async () => {
		await fetchData();

		setShowMfaFlowModals(false);
		showAlert({
			variant: 'success',
			children: () => {
				return <p className="m-0 text-white">MFA setup complete.</p>;
			},
		});
	};

	return (
		<>
			{user && (
				<>
					<MfaFlowModals
						user={user}
						firstTimeSetup={false}
						show={showMfaFlowModals}
						onHide={() => {
							setShowMfaFlowModals(false);
						}}
						onComplete={() => {
							handleMfaFlowComplete();
						}}
					/>
				</>
			)}

			<Container className="py-6">
				<AsyncPage fetchData={fetchData}>
					<Row>
						<Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
							<Card>
								<Card.Header>
									<Card.Title>Choose Your Multi-Factor Authentication (MFA) Method</Card.Title>
								</Card.Header>
								<Card.Body>
									<div className="mb-5">
										{preferredMfaType === PREFERRED_MFA_TYPES.NOMFA && (
											<p className="mb-0">You do not have a MFA method.</p>
										)}

										{preferredMfaType === PREFERRED_MFA_TYPES.SMS_MFA && (
											<>
												<p className="mb-2">
													Your current MFA method is set to <strong>SMS</strong>: (
													<strong>{userPhoneNumber}</strong>).
												</p>
												<p className="mb-0">
													This requires you to authenticate authenticate using a code sent via
													text message to your phone.
												</p>
											</>
										)}
										{preferredMfaType === PREFERRED_MFA_TYPES.SOFTWARE_TOKEN_MFA && (
											<>
												<p className="mb-2">
													Your current MFA method is to use an{' '}
													<strong>Authenticator App</strong>.
												</p>
												<p className="mb-0">
													This requires you to authenticate using a code generated by an app
													installed on your mobile device or computer.
												</p>
											</>
										)}
									</div>
									<div className="text-right">
										<Button
											variant="link"
											className="mr-4"
											onClick={handleChangeMfaMethodButtonClick}
										>
											Change MFA Method
										</Button>
										<Button
											onClick={() => {
												redirectUser();
											}}
										>
											Done
										</Button>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</AsyncPage>
			</Container>
		</>
	);
};
